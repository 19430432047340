import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Clipboard from 'v-clipboard';
import i18n from './i18n';

//i18n.locale = 'de';

// get local from browser
const browserLang = navigator.language.split('-')[0];
i18n.locale = browserLang;

Vue.config.productionTip = false;
Vue.use(Clipboard);
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
